.circle-background,
.circle-progress {
    fill: none;
}

.circle-background {
    stroke: #ddd;
}

.circle-progress {
    stroke-linecap: round;
    stroke-linejoin: round;
}

.text-percent {
    font-size: 1.8em;
    font-weight: bold;
}

.text-completed {
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: bold;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.upload-stroke {
    stroke: #242C52;
}

.upload-fill {
    fill: #242C52;
}

.deleting-stroke {
    stroke: #31919D;
}

.deleting-fill {
    fill: #31919D;
}